<template>
  <ion-page>
    <ion-content class="ion-padding ion-padding-end pt-5">
      <div class="main-content h-100">
        <div class="is-flex ion-align-items-center ion-justify-content-center is-flex-direction-column ion-margin-bottom">
          <p class="mb-1 fz-24 fw-300">{{ $t('profile.welcomeText') }}</p>

          <h2 class="title fw-400">Medicast</h2>
        </div>

        <div>
          <AppSelect
            v-model="salutation"
            class="ion-margin-bottom"
            :placeholder="$t('profile.salutationLabel')"
            :items="[{id: 'Dhr.', name: 'Dhr.'}, {id: 'Mevr.', name: 'Mevr.'},]"
          />

          <AppInput
            class="w-100 ion-margin-bottom"
            :placeholder="$t('profile.titleLabel')"
            :value="title"
            @input:update="title = $event"
          />

          <AppInput
            class="w-100 ion-margin-bottom"
            :placeholder="$t('profile.firstNameLabel')"
            :value="firstName"
            @input:update="firstName = $event"
          />

          <AppInput
            class="w-100 ion-margin-bottom"
            :placeholder="$t('profile.insertionLabel')"
            :value="insertion"
            @input:update="insertion = $event"
          />

          <AppInput
            class="w-100 ion-margin-bottom"
            :placeholder="$t('profile.lastNameLabel')"
            :value="lastName"
            @input:update="lastName = $event"
          />

          <AppInput
            class="w-100 ion-margin-bottom"
            :placeholder="$t('profile.streetLabel')"
            :value="street"
            @input:update="street = $event"
          />

          <AppInput
            class="w-100 ion-margin-bottom"
            :placeholder="$t('profile.additionLabel')"
            :value="addition"
            @input:update="addition = $event"
          />

          <p class="offer-label">{{ $t('profile.sendOffersLabel') }}</p>
          <AppSelect
            v-model="specialOffers"
            :placeholder="$t('profile.specialOffersLabel')"
            :items="[{id: 'Never', name: $t('profile.specialOffers.never')}, {id: 'Monthly', name: $t('profile.specialOffers.monthly')}, {id: 'Weekly', name: $t('profile.specialOffers.weekly')}, {id: 'Daily', name: $t('profile.specialOffers.daily')},]"
          />

          <ion-item>
            <ion-checkbox
              :checked="newsletterAbroad"
              :model-value="newsletterAbroad"
              color="primary"
              slot="start"
              @update:modelValue="newsletterAbroad = $event"
            />
            <ion-label class="offer-check">{{ $t('profile.sendMeOffersCheckBox') }}</ion-label>
          </ion-item>

        </div>

        <div style="margin-top: auto">
          <ion-row>
            <ion-col>
              <ion-button
                @click="redirectNext"
                color="light"
                expand="block"
                mode="ios"
              >
                {{$t('commonKeys.skip')}}
              </ion-button>
            </ion-col>
            <ion-col>
              <ion-button
                expand="block"
                mode="ios"
                @click="verifyProfile"
              >
                {{$t('verifyProfile.title')}}
              </ion-button>
            </ion-col>
          </ion-row>
        </div>
      </div>
      <IonLoading :is-open="loading" />
    </ion-content>
  </ion-page>
</template>

<script>
import AppInput from '@/components/common/AppInput.vue';
import AppSelect from '@/components/common/AppSelect.vue';
import useRequestToast from "@/composables/useRequestToast";

import {
  IonContent,
  IonPage,
  IonCol,
  IonRow,
  IonButton,
  IonItem,
  IonCheckbox,
  IonLabel,
  IonLoading,
} from '@ionic/vue';
import { mapGetters } from "vuex";

export default {
  name: "CompleteProfile",

  components: {
    AppInput,
    AppSelect,
    IonContent,
    IonPage,
    IonCol,
    IonRow,
    IonButton,
    IonItem,
    IonCheckbox,
    IonLabel,
    IonLoading,
  },

  data () {
    const { openErrorToast } = useRequestToast();

    return {
      loading: false,
      openErrorToast,
    };
  },

  computed: {
    ...mapGetters('profile', ['getProfileDetails', 'profileDetailsFetched']),

    salutation: {
      get() { return this.getProfileDetails.salutation; },
      set (value) { this.$store.commit('profile/updateProfileDetails', { salutation: value });},
    },
    firstName: {
      get() { return this.getProfileDetails.first_name; },
      set (value) { this.$store.commit('profile/updateProfileDetails', { first_name: value });},
    },
    title: {
      get() { return this.getProfileDetails.title; },
      set (value) { this.$store.commit('profile/updateProfileDetails', { title: value });},
    },
    insertion: {
      get() { return this.getProfileDetails.insertion; },
      set (value) { this.$store.commit('profile/updateProfileDetails', { insertion: value });},
    },
    street: {
      get() { return this.getProfileDetails.street; },
      set (value) { this.$store.commit('profile/updateProfileDetails', { street: value });},
    },
    lastName: {
      get() { return this.getProfileDetails.last_name; },
      set (value) { this.$store.commit('profile/updateProfileDetails', { last_name: value });},
    },
    number: {
      get() { return this.getProfileDetails.number; },
      set (value) { this.$store.commit('profile/updateProfileDetails', { number: value });},
    },
    addition: {
      get() { return this.getProfileDetails.street_addition; },
      set (value) { this.$store.commit('profile/updateProfileDetails', { street_addition: value });},
    },
    specialOffers: {
      get() { return this.getProfileDetails.newsletter_frequency || 'Monthly'; },
      set (value) { this.$store.commit('profile/updateProfileDetails', { newsletter_frequency: value });},
    },
    newsletterAbroad: {
      get() { return this.getProfileDetails.newsletter_abroad !== 0; },
      set (value) { this.$store.commit('profile/updateProfileDetails', { newsletter_frequency: value });},
    },

  },


  methods: {
    verifyProfile () {
      this.loading = true;
      this.$store
        .dispatch('profile/completeProfile', {
          salutation: this.salutation,
          title: this.title,
          first_name: this.firstName,
          insertion: this.insertion,
          last_name: this.lastName,
          street: this.street,
          street_number: this.number,
          street_addition: this.addition,
          newsletter_frequency: this.specialOffers,
          newsletter_abroad: this.newsletterAbroad,
        })
        .then(() => this.redirectNext())
        .catch((err) => {
          this.openErrorToast(err);
        })
        .finally(() => this.loading = false);
    },

    redirectNext() {
      this.$router.push('/');
    },
  },
};
</script>

<style scoped lang="scss">
  .main-content {
    padding-top: 5vh;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .title {
    font-size: 50px;
  }

  .subtitle {
    padding-left: 40px;
  }
  .offer-check {
    font-size: 14px;
    white-space: normal;
  }
  .offer-label {
    padding-left: 20px;
  }

  @media screen and (max-width: 320px) {
    .title {
      font-size: 60px;
    }
  }
</style>
